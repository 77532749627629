import i18n from '@/libs/i18n'

export default [
  {
    path: '/account/forgot-password',
    name: 'account-forgot-password',
    component: () => import('@/views/account/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/account/forgot-password-reset/:token',
    name: 'account-forgot-password-reset',
    component: () => import('@/views/account/ForgotPasswordReset.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/account/settings',
    name: 'account-settings',
    component: () => import('@/views/account/account-setting/AccountSetting.vue'),
    meta: {
      resource: 'Auth',
      pageTitle: i18n.t('account.title'),
      breadcrumb: [
        {
          text: i18n.t('account.title'),
          active: true,
        },
      ],
    },
  },
]
