const { VUE_APP_API_BASE_URL } = process.env

export default {
  // Endpoints
  loginEndpoint: `${VUE_APP_API_BASE_URL}/auth/login`,
  registerEndpoint: `${VUE_APP_API_BASE_URL}/auth/register`,
  refreshEndpoint: `${VUE_APP_API_BASE_URL}/auth/refresh`,
  logoutEndpoint: `${VUE_APP_API_BASE_URL}/auth/logout`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
