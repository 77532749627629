import Vue from 'vue'
import VueRouter from 'vue-router'
// import useJwt from '@/auth/jwt/useJwt'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import auth from './routes/auth'
import equipments from './routes/equipments'
import publicity from './routes/publicity'
import users from './routes/users'
import account from './routes/account'
import error from './routes/error'
import call from './routes/call'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'auth-login' } },
    ...auth,
    ...account,
    ...error,
    ...equipments,
    ...publicity,
    ...users,
    ...call,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      return next({ name: 'auth-login' })
    }

    const userData = getUserData()
    if (to.fullPath === '/publicity' && userData.role === 'ClientAdministrator') {
      return next({ name: 'all-publicities-client-admin', params: { client: userData.clientName } })
    } else if (to.fullPath === '/publicity' && userData.role !== 'Administrator') {
      return next({ name: 'all-publicities-client', params: { client: userData.clientName } })
    }

    // If logged in => not authorized
    if (to.name !== 'error-not-authorized' && to.name !== 'error-not-available' && to.name !== 'error-404' && to.name !== 'error-under-maintenance') {
      return next({ name: 'error-not-authorized' })
    }
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
