import axios from 'axios'

const loadedUserData = JSON.parse(localStorage.getItem('userData'))

const state = {
  agentAvailable: false,
}

const mutations = {
  SET_AGENT_AVAILABLE(localState, value) {
    localState.agentAvailable = value
  },
}

const actions = {
  async toggleAgentAvailable({ commit, state: localState, dispatch }) {
    const previousValue = localState.agentAvailable
    const newValue = !localState.agentAvailable
    commit('SET_AGENT_AVAILABLE', newValue)
    const success = await dispatch('updateDeviceTwin', newValue)
    if (!success) {
      commit('SET_AGENT_AVAILABLE', previousValue)
    }
    return success
  },
  async updateDeviceTwin({ rootState }, value) {
    const userData = JSON.parse(localStorage.getItem('userData')) || loadedUserData
    const data = { agentAvailable: value }
    const equipments = rootState.equipment.equipments.map(item => item.serialNumber).join(',')
    const url = `${process.env.VUE_APP_FUNCTION_BASE_URL}/proxy/update?code=${process.env.VUE_APP_FUNCTION_PROXY_UPDATE_KEY}&equipments=${equipments}&username=${userData.username}&client=${userData.clientName}`

    const maxAttempts = 3
    for (let attempt = 1; attempt <= maxAttempts; attempt++) {
      try {
        await axios.post(url, data)
        return true // Success
      } catch (error) {
        if (attempt === maxAttempts) {
          return false // Failed after max attempts
        }
      }
    }
    return false // Return false if all attempts fail
  },
  updateDeviceTwinSync({ rootState }, value) {
    const userData = JSON.parse(localStorage.getItem('userData')) || loadedUserData
    const data = { agentAvailable: value }
    const equipments = rootState.equipment.equipments.map(item => item.serialNumber).join(',')
    const url = `${process.env.VUE_APP_FUNCTION_BASE_URL}/proxy/update?code=${process.env.VUE_APP_FUNCTION_PROXY_UPDATE_KEY}&equipments=${equipments}&username=${userData.username}&client=${userData.clientName}`
    const payload = JSON.stringify(data)

    const maxAttempts = 3
    for (let attempt = 1; attempt <= maxAttempts; attempt++) {
      try {
        const success = navigator.sendBeacon(url, payload)
        if (success) {
          return true // Success
        } else {
          fetch(url, {
            method: 'POST',
            body: payload,
            headers: {
              'Content-Type': 'application/json',
            },
          }).then(() => true) // Success
            .catch(() => {
              if (attempt === maxAttempts) {
                return false // Failed after max attempts
              }
              return false // Add a return statement
            })
        }
      } catch (error) {
        if (attempt === maxAttempts) {
          return false // Failed after max attempts
        }
      }
    }
    return false // Return false if all attempts fail
  },
}

const getters = {
  agentAvailable: localState => localState.agentAvailable,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
