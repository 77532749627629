import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'

const equipmentMap = {
  BRN: 'borne',
  STN: 'station',
  ARC: 'arche',
  BTI: 'borneTouristique',
  BTE: 'borneTouristiqueExterieure',
  LMP: 'lampadaire',
  RMQ: 'remorque',
  KRS: 'kairos',
  BVC: 'borneVC',
  BVR: 'borneVR',
}

const state = {
  equipments: [],
}

const baseUrl = process.env.VUE_APP_API_BASE_URL

const mutations = {
  setEquipments(stateObj, equipments) {
    stateObj.equipments = equipments
  },
  updateEquipmentConnectionState(stateObj, updatedEquipments) {
    stateObj.equipments = stateObj.equipments.map(equipment => {
      const updatedEquipment = updatedEquipments.find(item => item.serialNumber === equipment.serialNumber)
      return updatedEquipment ? { ...equipment, ...updatedEquipment } : equipment
    })
  },
  updatedEquipmentCallHistory(stateObj, updatedEquipments) {
    stateObj.equipments = stateObj.equipments.map(equipment => {
      const updatedEquipment = updatedEquipments.find(item => item.serialNumber === equipment.serialNumber)
      return updatedEquipment ? { ...equipment, ...updatedEquipment } : equipment
    })
  },
}

function getEquipmentImagePaths(equipment, clientName) {
  // Extract the prefix from the serial number
  const prefix = equipment.serialNumber.match(/^[A-Za-z]+/)[0]
  const equipmentType = equipmentMap[prefix]
  const capitalizedEquipmentType = equipmentType.charAt(0).toUpperCase() + equipmentType.slice(1)

  return {
    imagePath: require(`@/assets/images/ht/equipments/${equipmentType}/${prefix}_${clientName}_small.png`),
    largeImagePath: require(`@/assets/images/ht/equipments/${equipmentType}/${capitalizedEquipmentType}_${clientName}.png`),
  }
}

const actions = {
  async fetchEquipments({ commit, dispatch }) {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const token = useJwt.getToken()

    try {
      const response = await axios.get(`${baseUrl}/equipments?client=${userData.clientName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          token: token,
        },
      })
      const equipments = response.data
        .filter(equipment => equipment.calling)
        .map(equipment => {
          const { imagePath, largeImagePath } = getEquipmentImagePaths(equipment, userData.clientName)
          return {
            serialNumber: equipment.serialNumber,
            name: equipment.name,
            status: false,
            imagePath: imagePath,
            largeImagePath: largeImagePath,
            latitude: equipment.latitude,
            longitude: equipment.longitude,
            connectionState: false,
            callHistory: [],
          }
        })
      commit('setEquipments', equipments)
      dispatch('fetchLiveStats', equipments)
      dispatch('fetchCallHistory', equipments)
    // eslint-disable-next-line no-empty
    } catch (error) {
    }
  },
  async fetchLiveStats({ commit, state: rootState }) {
    const equipments = rootState.equipments.map(equipment => equipment.serialNumber).join(',')
    const response = await axios.get(
      `${process.env.VUE_APP_FUNCTION_BASE_URL}/proxy/ping`,
      {
        params: {
          code: process.env.VUE_APP_FUNCTION_PROXY_KEY,
          equipments: equipments,
        },
      },
    )
    if (Array.isArray(response.data)) {
      const updatedEquipments = response.data.map(item => {
        const foundItem = rootState.equipments.find(equipment => equipment.serialNumber === item.serialNumber)
        if (foundItem) {
          return {
            ...foundItem,
            connectionState: item.connectionState === 'Connected',
          }
        }
        return foundItem
      })
      commit('updateEquipmentConnectionState', updatedEquipments)
    }
  },
  async fetchCallHistory({ commit, state: rootState }) {
    const serialNumbers = rootState.equipments.map(equipment => equipment.serialNumber).join(',')
    const token = useJwt.getToken()

    try {
      const response = await axios.get(`${baseUrl}/call/history?serialNumbers=${serialNumbers}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          token: token,
        },
      })
      const callHistory = response.data

      // Add call history to the equipment objects
      const equipmentsWithCallHistory = rootState.equipments.map(equipment => ({
        ...equipment,
        callHistory: callHistory[equipment.serialNumber] || [],
      }))

      commit('updatedEquipmentCallHistory', equipmentsWithCallHistory)
      // eslint-disable-next-line no-empty
    } catch (error) {
    }
  },
  // eslint-disable-next-line no-unused-vars
  async postCallHistory({ commit }, call) {
    const token = useJwt.getToken()

    try {
      await axios.post(`${baseUrl}/call/history`, call, {
        headers: {
          Authorization: `Bearer ${token}`,
          token: token,
        },
      })
    // eslint-disable-next-line no-empty
    } catch (error) {
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
