export default [
  {
    path: '/error/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error/not-authorized',
    name: 'error-not-authorized',
    component: () => import('@/views/error/NotAuthorized.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error/under-maintenance',
    name: 'error-under-maintenance',
    component: () => import('@/views/error/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error/not-available',
    name: 'error-not-available',
    component: () => import('@/views/error/NotAvailable.vue'),
  },
]
