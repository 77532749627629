export default [
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/user/users-list/UsersList.vue'),
    meta: {
      resource: 'users',
      action: 'manage',
    },
  },
  {
    path: '/users/list-client-admin',
    name: 'users-list-client-admin',
    component: () => import('@/views/user/users-list/UsersListClientAdmin.vue'),
    meta: {
      resource: 'users',
      action: 'manage',
    },
  },
  {
    path: '/users/edit/:username',
    name: 'users-edit',
    component: () => import('@/views/user/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'users',
      action: 'manage',
    },
  },
]
