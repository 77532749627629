// eslint-disable-next-line
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@aspnet/signalr'

export default {
  install(Vue) {
    const { VUE_APP_API_BASE_URL } = process.env

    const connection = new HubConnectionBuilder()
      .withUrl(`${VUE_APP_API_BASE_URL}/signalr-hub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Trace)
      .build()

    async function start() {
      try {
        await connection.start()
      } catch (err) {
        setTimeout(start, 5000)
      }
    }

    connection.onclose(async () => {
      await start()
    })

    // use new Vue instance as an event bus
    const signalRHub = new Vue()
    // every component will use this.$signalRHub to access the event bus
    Vue.prototype.$signalRHub = signalRHub

    connection.on('ReceiveBorneLog', borneRealtimeStats => {
      signalRHub.$emit('received-borne-log', { borneRealtimeStats })
    })

    connection.on('ReceiveRemorqueLog', remorqueRealtimeStats => {
      signalRHub.$emit('received-remorque-log', { remorqueRealtimeStats })
    })

    connection.on('ReceiveStationLog', stationRealtimeStats => {
      signalRHub.$emit('received-station-log', { stationRealtimeStats })
    })

    connection.on('ReceiveArcheLog', archeRealtimeStats => {
      signalRHub.$emit('received-arche-log', { archeRealtimeStats })
    })

    connection.on('ReceiveLampadaireLog', lampadaireRealtimeStats => {
      signalRHub.$emit('received-lampadaire-log', { lampadaireRealtimeStats })
    })

    connection.on('ReceiveBorneTouristiqueLog', borneTouristiqueLog => {
      signalRHub.$emit('received-borneTouristique-log', { borneTouristiqueLog })
    })

    connection.on('ReceiveBorneTouristiqueTelemetry', borneTouristiqueTelemetry => {
      signalRHub.$emit('received-borneTouristique-telemetry', { borneTouristiqueTelemetry })
    })

    connection.on('ReceiveBorneTouristiqueExterieureLog', borneTouristiqueExterieureLog => {
      signalRHub.$emit('received-borneTouristiqueExterieure-log', { borneTouristiqueExterieureLog })
    })

    connection.on('ReceiveBorneTouristiqueExterieureTelemetry', borneTouristiqueExterieureTelemetry => {
      signalRHub.$emit('received-borneTouristiqueExterieure-telemetry', { borneTouristiqueExterieureTelemetry })
    })

    connection.on('ReceiveBorneTouristiqueExterieureWebhook', borneTouristiqueExterieureWebhook => {
      signalRHub.$emit('received-borneTouristiqueExterieure-webhook', { borneTouristiqueExterieureWebhook })
    })

    // Start the connection.
    start()
  },
}
