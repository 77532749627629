export default [
  {
    path: '/equipments',
    name: 'equipment-types',
    component: () => import('@/views/equipments/Types.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/brn/:client',
    name: 'equipment-bornes',
    component: () => import('@/views/equipments/bornes/Bornes.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/brn/stats/:serialNumber',
    name: 'equipment-borne',
    component: () => import('@/views/equipments/bornes/BorneView.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/station/:client',
    name: 'equipment-stations',
    component: () => import('@/views/equipments/stations/Stations.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/stn/stats/:serialNumber',
    name: 'equipment-station',
    component: () => import('@/views/equipments/stations/StationView.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/rmq/:client',
    name: 'equipment-remorques',
    component: () => import('@/views/equipments/remorques/Remorques.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/rmq/stats/:serialNumber',
    name: 'equipment-remorque',
    component: () => import('@/views/equipments/remorques/RemorqueView.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/kairos/:client',
    name: 'equipment-kaiross',
    component: () => import('@/views/error/UnderMaintenance.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/borne-residentielle/:client',
    name: 'equipment-borneVRs',
    component: () => import('@/views/error/UnderMaintenance.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/borne-commerciale/:client',
    name: 'equipment-borneVCs',
    component: () => import('@/views/error/UnderMaintenance.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/arche/:client',
    name: 'equipment-arches',
    component: () => import('@/views/equipments/arches/Arches.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/arche/stats/:serialNumber',
    name: 'equipment-arche',
    component: () => import('@/views/equipments/arches/ArcheView.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/lampadaire/:client',
    name: 'equipment-lampadaires',
    component: () => import('@/views/equipments/lampadaires/Lampadaires.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/lampadaire/stats/:serialNumber',
    name: 'equipment-lampadaire',
    component: () => import('@/views/equipments/lampadaires/LampadaireView.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/borne-touristique/:client',
    name: 'equipment-borneTouristiques',
    component: () => import('@/views/equipments/borneTouristiques/BorneTouristiques.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/borne-touristique/stats/:serialNumber',
    name: 'equipment-borneTouristique',
    component: () => import('@/views/equipments/borneTouristiques/BorneTouristiqueView.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/borne-touristique-exterieure/:client',
    name: 'equipment-borneTouristiqueExterieures',
    component: () => import('@/views/equipments/borneTouristiqueExterieures/BorneTouristiqueExterieures.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/borne-touristique-exterieure/stats/:serialNumber',
    name: 'equipment-borneTouristiqueExterieure',
    component: () => import('@/views/equipments/borneTouristiqueExterieures/BorneTouristiqueExterieureView.vue'),
    meta: {
      resource: 'equipment',
    },
  },
  {
    path: '/equipments/management',
    name: 'equipment-mangement',
    component: () => import('@/views/equipments/management/EquipmentManagement.vue'),
    meta: {
      resource: 'administrator',
    },
  },
]
